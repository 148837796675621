<template>
  <span>
    <v-chip
      class="mx-1"
      small
      v-for="(user) in partners"
      :key="user.username"
    >
      {{ user.username }}
    </v-chip>
  </span>
</template>

<script>
export default {
  name: 'PartnerListChips',
  props: {
    partners: {
      type: Array,
      required: true
    }
  }
}
</script>
