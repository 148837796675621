<!--
Trip Blueprints are used as a starting point for a trip. Giving buyers an outline of where they should be going
-->
<template>
  <unauthorized
    :authorized="$store.getters.can('view-blueprint')"
    message="Not Authorized to view Blueprint"
  >
    <div v-if="entry">
      <url-breadcrumbs
        :items="[
          ['Blueprint List', { name: 'blueprints-list' }],
          entry.url
        ]"
      />
      <v-container>
        <base-title>
          Blueprint #{{ entry.id }}
          <br>
          {{ entry.name || '--' }} ({{ entry.state || '--' }})
        </base-title>
        <v-toolbar
          flat
          dense
        >
          <v-spacer />
          <!-- delete -->
          <v-btn
            small
            v-if="$store.getters.can('delete-blueprint')"
            class="mr-2"
            color="accent"
            @click="deleteDialog = true"
          >
            Delete
          </v-btn>
          <base-dialog
            v-model="deleteDialog"
            headline-text="Delete Blueprint"
            :message-text="`Are you sure you would like to delete Blueprint ${entry.id}?`"
            decline-text="Cancel"
            accept-text="Delete"
            @accept="deleteBlueprint()"
          />
          <v-btn
            :disabled="!$store.getters.can('edit-blueprint')"
            class="mr-2"
            small
            color="primary"
            @click="openEditingDialog"
          >
            Edit
          </v-btn>
          <!--          <v-btn-->
          <!--            :disabled="!$store.getters.can('create-trip')"-->
          <!--            small-->
          <!--            color="primary"-->
          <!--            @click="createTripDialog = true"-->
          <!--          >-->
          <!--            Create Trip-->
          <!--          </v-btn>-->
        </v-toolbar>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                Included Yards
              </v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item
                    v-for="(yard, index) in entry.yards_details"
                    :key="index"
                  >
                    <v-list-item-content>
                      <router-link-id
                        :id="yard.id"
                        name="yard"
                      >
                        {{ yard.name }} -- {{ yard.status_text }}
                      </router-link-id>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <edit-dialog
        v-model="editingDialog"
        headline-text="Edit Blueprint"
        @accept="saveChanges"
      >
        Edit the Blueprint
        <v-form>
          <v-card flat>
            <v-card-text>
              <v-text-field
                label="Name"
                v-model="name"
              />
              <v-autocomplete
                label="State"
                v-model="state"
                :items="$store.getters['getStateList']"
                item-text="text"
                item-value="value"
              />
            </v-card-text>
          </v-card>
          <v-card flat>
            <v-card-title>
              Attached Yards
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="(yard, index) in yards"
                  :key="index"
                  @click="yards = yards.filter(y => y.id !== yard.id)"
                >
                  <v-list-item-content>
                    {{ yard.name }}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      color="accent"
                      fab
                      icon
                      small
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <!--Maybe switch this with a standard autocomplete-->
              <search-autocomplete
                :params="{ page_size: 0, fields: 'id,url,name,status_text', ordering: 'name', status: 'GS' }"
                label="Yard Select (GOODSTANDING)"
                api-path="/api/yards/"
                clearable
                :format-entry="x => ({friendlyName: x.name, id: x.id, name: x.name, url: x.url})"
                @input="addYard"
              />
            </v-card-text>
          </v-card>
        </v-form>
      </edit-dialog>
      <!--      <edit-dialog-->
      <!--        v-model="createTripDialog"-->
      <!--        headline-text="Confirm"-->
      <!--        max-width="400"-->
      <!--        @accept="createTrip"-->
      <!--      >-->
      <!--        Are you sure you want to create a new trip?-->
      <!--      </edit-dialog>-->
    </div>
  </unauthorized>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
export default {
  name: 'TripBlueprint',
  components: { SearchAutocomplete },
  data: () => ({
    name: '',
    state: '',
    yards: [],
    // createTripDialog: false,
    editingDialog: false,
    deleteDialog: false
  }),
  computed: {
    // Gets the lab entry from the store
    entry () {
      return this.$store.state['tripBlueprintStore'].items[this.$route.params.id]
    }
  },
  methods: {
    // Opens the dialog and sets the related values
    openEditingDialog () {
      if (!this.entry) return
      this.name = this.entry.name
      this.state = this.entry.state
      this.yards = this.entry.yards_details
      this.editingDialog = true
    },
    // attempts to delete entry. if successful, route to blueprint list, else throw error
    deleteBlueprint () {
      let entryId = this.$route.params.id
      this.$store.dispatch('tripBlueprintStore/deleteTripBlueprint', entryId).then((res) => {
        if (res.status === 204) {
          this.$store.commit('setSnackbarSuccess', `Blueprint ${entryId} has been deleted`)
          this.$router.push({ name: 'blueprints-list' })
        } else {
          this.deleteDialog = false
          this.$store.commit('setSnackbarError', `Blueprint ${entryId} could not be deleted`)
        }
      })
    },
    // Creates a new trip using this blueprint
    // createTrip () {
    //   this.createTripDialog = false
    // },
    // Saves the Blueprint Model
    saveChanges () {
      const payload = {
        name: this.name,
        state: this.state,
        yards: this.yards.map(y => y.url)
      }
      this.$store.dispatch('tripBlueprintStore/patchTripBlueprint', { id: this.$route.params.id, payload })
      this.editingDialog = false
    },
    // Checks to see if yard is already added before appending
    addYard (yard) {
      if (!yard) return
      if (this.yards.find(y => y.id === yard.id)) {
        this.$store.commit('setSnackbarInfo', 'This yard is already attached.')
        return
      }
      this.yards.push(yard)
    }
  },
  mounted () {
    this.$store.dispatch('tripBlueprintStore/fetchTripBlueprint', this.$route.params.id)
  }
}
</script>
