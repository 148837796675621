var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters.isTripMember(_vm.trip))?_c('v-form',{on:{"submit":_vm.addYard}},[_c('v-card',[_c('v-card-subtitle',[_vm._v(" Add a Yard ")]),_c('v-card-text',[_c('search-autocomplete',{attrs:{"params":{ page_size: 0, fields: 'name,url,state', ordering: 'name', status: 'GS' },"label":"Yard Search (Good Standing)","api-path":_vm.apiPath,"format-entry":function (x) { return ({ friendlyName: ((x.name) + " (" + (x.state) + ")"), name: x.name, url: x.url }); }},model:{value:(_vm.yardToAdd),callback:function ($$v) {_vm.yardToAdd=$$v},expression:"yardToAdd"}}),(_vm.yardAlreadyIncluded)?_c('p',{staticClass:"warning--text"},[_vm._v(" This yard is already included. ")]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","type":"submit"}},[_vm._v(" Add Yard ")])],1)],1)],1):_vm._e(),_c('v-card',{attrs:{"flat":""}},[_c('v-card-subtitle',[_vm._v(" Sales ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":_vm.readonly,"sort-by":"yard_details.name","headers":_vm.headers,"items":_vm.trip.tripsale_set_details,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.openEditDialog},scopedSlots:_vm._u([{key:"item.yard_details.name",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(" "+_vm._s(item.yard_details.name)+" ")])]}},{key:"item.check_in_id",fn:function(ref){
var item = ref.item;
return [(item.check_in_id)?_c('router-link-id',{attrs:{"id":item.check_in_id,"name":"check-in"}},[_vm._v(" "+_vm._s(item.check_in_id)+" ")]):_vm._e()]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [(item.comments)?_c('info-tooltip',{scopedSlots:_vm._u([{key:"wrap-me",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-comment-text-outline ")])],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.truncateComments(item.comments))+" ")]):_vm._e()]}},{key:"item.expected_pieces",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expected_pieces > 0 ? item.expected_pieces : '--')+" ")]}},{key:"item.expected_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expected_value > 0 ? _vm.currencyFmt(item.expected_value) : '--')+" ")]}},{key:"item.check_in_total_counts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.check_in_total_counts > 0 ? item.check_in_total_counts : '--')+" ")]}},{key:"item.check_in_total_prices",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.check_in_total_prices > 0 ? _vm.currencyFmt(item.check_in_total_prices) : '--')+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v(" Totals ")]),_c('th',[_vm._v(" "+_vm._s(_vm.sumField('expected_pieces'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.currencyFmt(_vm.sumField('expected_value')))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.sumField('check_in_total_counts'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.currencyFmt(_vm.sumField('check_in_total_prices')))+" ")]),_c('th')])])],2),_c('div',{staticClass:"text-center"},[_c('small',[_vm._v(" Click on a row for editing/details. ")])])],1),(_vm.selectedSale)?_c('edit-sale',{attrs:{"sale":_vm.selectedSale,"trip":_vm.trip},on:{"updated":_vm.refreshTrip},model:{value:(_vm.editSaleDialog),callback:function ($$v) {_vm.editSaleDialog=$$v},expression:"editSaleDialog"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }