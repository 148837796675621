<template>
  <unauthorized
    :authorized="$store.getters.can('view-spending-plan-reports')"
    message="Unauthorized to view Spending Reports"
  >
    <div v-if="entry">
      <UsdForcedAlert />
      <url-breadcrumbs
        class="d-print-none"
        :items="[
          ['Spending Plan List', { name: 'spending-plan-report-list' }],
          entry.url
        ]"
      />
      <v-container fluid>
        <base-title>
          Spending Report #{{ entry.id }}
          <template v-slot:subtitle>
            For Week Of {{ entry.week_of | formatDate }}
          </template>
        </base-title>
        <small>
          Report Generated On {{ entry.updated_at | formatDate }}
          <info-tooltip>
            Changes that occur after the generated date will not be reflected.
          </info-tooltip>
        </small>
        <v-toolbar
          class="d-print-none"
          dense
          flat
        >
          <v-spacer />
          <v-btn
            :disabled="!$store.getters.can('update-spending-plan-reports')"
            small
            text
            @click="sendEmails"
          >
            Send Emails
          </v-btn>
          <v-btn
            class="ml-2"
            :disabled="!$store.getters.can('update-spending-plan-reports')"
            small
            primary
            @click="updateReport"
          >
            Update Report
          </v-btn>
        </v-toolbar>
        <v-row
          v-for="(table, user) in tables"
          :key="user"
        >
          <v-col>
            <v-card
              flat
            >
              <v-card-title>
                {{ table.partners.join(', ') }}
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="table.rows"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:item.yard="{ item }">
                    {{ item.yard }}
                    <br>
                    <small>
                      <router-link-id
                        :id="item.trip_id"
                        name="trip"
                      >
                        {{ item.trip }}
                      </router-link-id>
                    </small>
                  </template>
                  <template v-slot:item.expected_pieces="{ item }">
                    {{ item.expected_pieces | formatNumber }}
                  </template>
                  <template v-slot:item.expected_value="{ item }">
                    {{ item.expected_value | currencyUSD }}
                  </template>
                  <template v-slot:item.is_cash="{ item }">
                    <span class="text-no-wrap">
                      <v-icon :color="item.is_electronic ? 'primary' : 'white'">
                        mdi-bank-transfer
                      </v-icon>
                      <v-icon :color="item.is_check ? 'primary' : 'white'">
                        mdi-checkbook
                      </v-icon>
                      <v-icon :color="item.is_cash ? 'primary' : 'white'">
                        mdi-cash
                      </v-icon>
                    </span>
                  </template>
                  <template v-slot:item.cash_amount="{ item }">
                    <span v-if="item.cash_amount">
                      {{ item.cash_amount | currencyUSD }}
                    </span>
                    <span v-else>
                      No
                    </span>
                  </template>
                  <template v-slot:item.day_of_week="{ item }">
                    {{ item.day_of_week || '--' }}
                  </template>
                  <template v-slot:body.append="{ header, item }">
                    <tr>
                      <td>
                        Totals
                      </td>
                      <td colspan="4">
                        <div
                          v-for="(info, i) in cashBreakdown(table.rows)"
                          :key="i"
                        >
                          Cash Needed for {{ info.day }} {{ info.amount | currencyUSD }}
                        </div>
                      </td>
                      <td class="text-right">
                        {{ totalColumn('expected_pieces', table.rows) | formatNumber }}
                      </td>
                      <td class="text-right">
                        {{ totalColumn('cash_amount', table.rows) | currencyUSD }}
                      </td>
                      <td class="text-right">
                        {{ totalColumn('expected_value', table.rows) | currencyUSD }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-for="(trip, index) in emptyTrips"
          :key="index"
        >
          <v-col>
            <v-card
              flat
            >
              <v-card-title>
                {{ trip.trip_partners.join(', ') }}
              </v-card-title>
              <v-card-text>
                <router-link-id
                  class="ml-2"
                  :id="trip.trip_id"
                  name="trip"
                >
                  {{ trip.trip }}
                </router-link-id> - {{ trip.trip_comments ? trip.trip_comments : 'No sales Listed' }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </unauthorized>
</template>

<script>

import UsdForcedAlert from '@/components/UsdForcedAlert.vue'

export default {
  name: 'SpendingReportView',
  components: { UsdForcedAlert },
  data: () => ({
    headers: [
      { text: 'Yard/Trip', value: 'yard', width: 200 },
      { text: 'Comments', value: 'comments', width: '400' },
      { text: 'Status', value: 'sale_status' },
      { text: 'Elec./Check/Cash', value: 'is_cash', ordering: false },
      { text: 'Day', value: 'day_of_week', align: 'right' },
      { text: 'Exp. Pieces', value: 'expected_pieces', align: 'right' },
      { text: 'Cash Needed', value: 'cash_amount', align: 'right' },
      { text: 'Exp. Value', value: 'expected_value', align: 'right' }
    ]
  }),
  computed: {
    emptyTrips () {
      if (this.entry && this.entry.spending_data && this.entry.spending_data.empty_trips) {
        return this.entry.spending_data.empty_trips
      }
      return []
    },
    sales () {
      if (this.entry && this.entry.spending_data && this.entry.spending_data.sales) {
        return this.entry.spending_data.sales
      }
      return []
    },
    entry () {
      return this.$store.state['spendingReportStore'].items[this.$route.params.id]
    },
    /**
     * Formats the rows and breaks them down by USER
     * @return {{}|*}
     */
    tables () {
      const makeTable = () => ({
        partners: new Set(),
        rows: []
      })
      if (!this.sales) return {}
      const tables = this.sales.reduce((t, row) => {
        if (!t[row.trip_user]) t[row.trip_user] = makeTable()
        t[row.trip_user].rows.push(row)
        row.trip_partners.forEach(p => {
          t[row.trip_user].partners.add(p)
        })
        return t
      }, {})
      for (const key in tables) {
        tables[key].partners = [...tables[key].partners.values()]
      }
      return tables
    }
  },
  methods: {
    sendEmails () {
      this.$store.dispatch('spendingReportStore/dispatchSpendingReportEmail', this.entry.id).then(() => {
        this.$store.commit('setSnackbarInfo', 'Report Emails Sent')
      })
    },
    updateReport () {
      if (confirm('Are you sure you would like to update this report?')) {
        const payload = { id: this.$route.params.id, payload: {} }
        this.$store.dispatch('spendingReportStore/patchSpendingReport', payload)
      }
    },
    cashBreakdown (rows) {
      const totals = rows.reduce((totals, row) => {
        if (!row.cash_amount) return totals
        const day = row.day_of_week || 'N/A'
        if (!totals[day]) totals[day] = 0
        totals[day] += row.cash_amount
        return totals
      }, {})
      return Object.keys(totals).map(day => ({ day, amount: totals[day] }))
    },
    totalColumn (columnName, rows) {
      return rows.reduce((accum, row) => {
        if (row[columnName]) accum += row[columnName]
        return accum
      }, 0)
    }
  },
  mounted () {
    this.$store.dispatch('spendingReportStore/fetchSpendingReport', { id: this.$route.params.id, config: { params: { currency_fx: 'USD' } } })
  }
}
</script>
