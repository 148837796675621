<template>
  <unauthorized
    :authorized="$store.getters.can('view-trips')"
    message="Not Authorized to view Trips"
  >
    <base-title title="Trip List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <date-range-input
        :start-date="fromStartDate"
        :end-date="toStartDate"
        start-date-label="From Start Date"
        end-date-label="To Start Date"
        @alterStartDate="fromStartDate = $event"
        @alterEndDate="toStartDate = $event"
      />
      <date-range-input
        :start-date="fromEndDate"
        :end-date="toEndDate"
        start-date-label="From End Date"
        end-date-label="To End Date"
        @alterStartDate="fromEndDate = $event"
        @alterEndDate="toEndDate = $event"
      />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <search-autocomplete
            v-model="username"
            :params="{ fields: 'username', ordering: 'username' }"
            label="Users"
            api-path="/api/users/"
            item-text="friendlyName"
            item-value="username"
            :format-entry="x => ({ friendlyName: x.username, username: x.username })"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="trip"
        />
      </template>
      <template v-slot:item.users="{ item }">
        {{ item.users_details.map(u => u.username).join(', ') }}
      </template>
      <template v-slot:item.date_start="{ item }">
        {{ item.date_start | formatDate }}
      </template>
      <template v-slot:item.date_end="{ item }">
        {{ item.date_end | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />

    <edit-dialog
      v-model="createTripDialog"
      headline-text="Create Trip"
      :inputs-valid="dates.length === 2 && tripName.length"
      accept-text="Create"
      @accept="createTrip"
    >
      The user creating the blueprint will automatically be assigned.
      <br>
      Please select a blueprint to use for your trip.
      <v-autocomplete
        label="Blueprints (optional)"
        v-model="blueprint"
        :items="blueprints"
        clearable
        item-text="name"
        return-object
        hint="Use a blueprint to seed the trip with yards"
      />
      <span v-if="blueprint && blueprint.state">
        State: {{ blueprint.state }}
      </span>
      <v-sheet
        v-scroll
        style="max-height: 240px; overflow-y: scroll;"
      >
        <v-list
          v-if="blueprint"
          dense
        >
          <v-list-item
            v-for="yard in blueprint.yards_details"
            :key="yard.id"
          >
            #{{ yard.id }} {{ yard.name }}
          </v-list-item>
        </v-list>
      </v-sheet>
      <v-row>
        <v-col>
          <rules-text
            label="Name*"
            validation-rules="required"
            v-model="tripName"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <div>
            <v-date-picker
              full-width
              v-model="dates"
              range
            />
            <br>
            <strong :class="{ 'red--text': dates.length !== 2 }">
              {{ datesFormatted }}
            </strong>
            <span
              v-show="dates.length !== 2"
              class="red--text"
            >
              Please select a date range.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-textarea
            label="Trip Comments"
            v-model="newComments"
          />
        </v-col>
      </v-row>
    </edit-dialog>
    <text-fab
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="createTripDialog = true"
    >
      Create Trip
    </text-fab>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import moment from 'moment'
import RulesText from '@/components/ValidationFields/RulesText.vue'

export default {
  components: { RulesText, SearchAutocomplete, ResultPagination, SearchFilters },
  mixins: [chipsMixin, paginationMixin],
  name: 'TripList',
  data: function () {
    return {
      tripName: '',
      name: '',
      dates: [],
      blueprint: null,
      newComments: '',
      createTripDialog: false,
      confirmCreate: false,
      pageName: 'trip-list',
      // yards
      headers: [
        { text: 'id', sortable: true, value: 'id' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Users', sortable: true, value: 'users' },
        { text: 'Comments', sortable: false, value: 'comments' },
        { text: 'Start Date', sortable: false, value: 'date_start' },
        { text: 'End Date', sortable: false, value: 'date_end' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'User', prop: 'username', type: String, query: 'users__username__in' },
        { name: 'Comments', prop: 'comments', type: String, query: 'comments__icontains' },
        { name: 'From Start Date', prop: 'fromStartDate', type: String, query: 'date_start__gte' },
        { name: 'To Start Date', prop: 'toStartDate', type: String, query: 'date_start__lte' },
        { name: 'From End Date', prop: 'fromEndDate', type: String, query: 'date_end__gte' },
        { name: 'To End Date', prop: 'toEndDate', type: String, query: 'date_end__lte' }
      ]),

      blueprints: []
    }
  },
  watch: {
    blueprint (blueprint) {
      if (blueprint) this.tripName = blueprint.name
    },
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    },
    // fetch blueprints when trip create opened
    createTripDialog (opened) {
      if (opened) {
        this.fetchBlueprints()
      }
    }
  },
  computed: {
    datesFormatted () {
      return this.dates.map(date => {
        return moment(date).format('MM/DD/YYYY')
      }).join(' - ')
    }
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        'id',
        'name',
        'users_details',
        'comments',
        'date_start',
        'date_end'
      ].join(',')
      axios.get('/api/trips/', { params }).then(this.setResponseResults)
    },
    fetchBlueprints () {
      const params = { page_size: 0, ordering: 'name' }
      axios.get('/api/trip_blueprints/', { params }).then(r => {
        if (r.status) {
          this.blueprints = r.data
        }
      })
    },
    // Creates a trip assigning the current user
    createTrip () {
      if (this.dates.length !== 2) {
        this.$store.commit('setSnackbarError', 'Can not create a trip without without dates.')
        return
      }
      const payload = {
        comments: this.newComments,
        date_start: this.dates[0],
        date_end: this.dates[1],
        expense_set: [],
        tripreport_set: [],
        tripsale_set: [],
        name: this.tripName
      }

      if (this.blueprint) {
        payload.blueprint = this.blueprint.url
      }

      this.$store.dispatch('tripStore/createTrip', payload).then(r => {
        if (r.status === 201) {
          this.$router.push({ name: 'trip', params: { id: r.data.id } })
        }
      })
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
