<template>
  <v-card>
    <v-card-title>
      Finance
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <sales-card
            :trip="trip"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SalesCard from '@/views/Trips/components/SalesCard'
export default {
  name: 'ExpensesCard',
  components: { SalesCard },
  props: {
    trip: {
      type: Object,
      required: true
    }
  },
  methods: {
    // Uses a simple currency format or '--'
    currencyFmt (value) {
      return value === 0 ? '--' : this.currency.format(value)
    },
    // Used in the footer to get a total
    sumField (key) {
      return this.reduceExpenseGroups().reduce((a, b) => a + (b[key] || 0), 0)
    }
  }
}
</script>
