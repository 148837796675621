<template>
  <unauthorized
    :authorized="$store.getters.can('view-blueprint')"
    message="Not Authorized to view Blueprints"
  >
    <base-title title="Blueprint List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <date-range-input
        :start-date="startDate"
        :end-date="endDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="startDate = $event"
        @alterEndDate="endDate = $event"
      />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.xs ? 12 : 6"
        >
          <v-autocomplete
            label="State"
            v-model="state"
            clearable
            :items="$store.getters.stateList"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <search-autocomplete
            v-model="yardName"
            :params="{ page_size: 0, fields: 'name', ordering: 'name' }"
            label="Yard Search"
            api-path="/api/yards/"
            item-value="name"
            multiple
            :format-entry="x => ({ friendlyName: x.name, name: x.name })"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="blueprint"
        />
      </template>
      <template v-slot:item.yards="{ item }">
        <v-chip
          color="transparent"
          x-small
          v-for="(yard, index) in item.yards_details"
          :key="index"
        >
          {{ yard.name }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-blueprint')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="confirmCreate = true"
    >
      Create Blueprint
    </text-fab>
    <edit-dialog
      v-model="confirmCreate"
      headline-text="Confirm"
      max-width="400"
      accept-text="Create"
      @accept="createBlueprint"
    >
      Once you've created a new blueprint you will be able to add yards and rename the blueprint.
    </edit-dialog>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'

export default {
  components: { SearchAutocomplete, ResultPagination, SearchFilters },
  mixins: [chipsMixin, paginationMixin],
  name: 'BlueprintList',
  data: function () {
    return {
      confirmCreate: false,
      pageName: 'blueprints-list',
      // yards
      headers: [
        { text: 'id', sortable: true, value: 'id' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'State', sortable: true, value: 'state' },
        { text: 'Yards', sortable: true, value: 'yards' },
        { text: 'Created', sortable: false, value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'State', prop: 'state', type: String, query: 'state' },
        { name: 'Yard', prop: 'yardName', type: String, query: 'yards__name__icontains' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    createBlueprint () {
      this.confirmCreate = false
      this.$store.dispatch('tripBlueprintStore/createTripBlueprint', { yards: [] }).then(bp => {
        if (bp.status === 201) {
          this.$router.push({ name: 'blueprint', params: { id: bp.data.id } })
        }
      })
    },
    async executeSearch () {
      const params = this.getQueryParams()
      axios.get('/api/trip_blueprints/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
