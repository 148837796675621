<template>
  <unauthorized
    :authorized="$store.getters.can('view-spending-plan-reports')"
    message="Not Authorized to view Spending Reports"
  >
    <base-title title="Spending Plan Report List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <date-range-input
        :start-date="weekOfStartDate"
        :end-date="weekOfEndDate"
        start-date-label="Week Of Start Date"
        end-date-label="Week Of End Date"
        @alterStartDate="weekOfStartDate = $event"
        @alterEndDate="weekOfEndDate = $event"
      />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="spending-plan-report"
        />
      </template>
      <template v-slot:item.week_of="{ item }">
        {{ item.week_of | formatDate }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
  </unauthorized>
</template>

<script>
import axios from 'axios'
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'

export default {
  components: { ResultPagination, SearchFilters },
  mixins: [chipsMixin, paginationMixin],
  name: 'SpendingReportList',
  data: function () {
    return {
      pageName: 'spending-plan-report-list',
      headers: [
        { text: 'Id', sortable: true, value: 'id' },
        { text: 'For Week', sortable: true, value: 'week_of' }
        // { text: 'Created', sortable: true, value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Week Of Start', prop: 'weekOfStartDate', type: String, query: 'week_of__gte' },
        { name: 'Week Of End', prop: 'weekOfEndDate', type: String, query: 'week_of__lte' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      axios.get('/api/spending_plans/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
