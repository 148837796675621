<template>
  <ValidationObserver
    v-slot="{ invalid }"
    ref="validationObserver"
  >
    <edit-dialog
      :value="value"
      headline-text="Edit/Options"
      @input="$emit('input', $event)"
      @accept="createExpense"
      :inputs-valid="!invalid && dates.length === 2"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <ValidationProvider
            rules="required|max:110"
            v-slot="{ errors }"
          >
            <v-text-field
              label="Trip Name"
              v-model="name"
              :error-messages="errors"
            />
          </ValidationProvider>
          <v-textarea
            label="Comments"
            v-model="comments"
            rows="10"
          />
        </v-col>
        <v-col
          class="text-center"
          cols="12"
          md="6"
        >
          <v-date-picker
            v-model="dates"
            range
          />
          <br>
          <strong :class="{ 'red--text': dates.length !== 2 }">
            {{ datesFormatted }}
          </strong>
          <span
            v-show="dates.length !== 2"
            class="red--text"
          >
            Please select a date range.
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Partners
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              label="List of available partners (active + trip-user)"
              multiple
              chips
              v-model="selectedPartners"
              :items="userOptions"
              item-text="username"
              return-object
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </edit-dialog>
  </ValidationObserver>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'TripOptionDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    trip: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedPartners: [],
    name: '',
    comments: '',
    dates: [],
    // Trip user List of all options
    tripUsersList: []
  }),
  watch: {
    value: {
      immediate: true,
      handler (opened) {
        if (opened) {
          // fetch trip users
          if (this.tripUsersList.length === 0) this.fetchTripUsers()
          this.selectedPartners = this.trip.users_details
          this.name = this.trip.name
          this.comments = this.trip.comments
          this.dates = [this.trip.date_start, this.trip.date_end]
        }
      }
    }
  },
  computed: {
    // all users options both Trip Users and currently attached
    userOptions () {
      return [...this.tripUsersList, ...this.trip.users_details]
    },
    datesFormatted () {
      return this.dates.map(date => {
        return moment(date).format('MM/DD/YYYY')
      }).join(' - ')
    }
  },
  methods: {
    createExpense () {
      const payload = {
        name: this.name,
        comments: this.comments,
        date_start: this.dates[0],
        date_end: this.dates[1],
        users: this.selectedPartners.map(p => p.url)
      }
      if (payload.users.length === 0) {
        this.$store.commit('setSnackbarError', 'There must be at least one trip partner.')
        return
      }
      this.$store.dispatch('tripStore/patchTrip', { id: this.trip.id, payload })
    },
    fetchTripUsers () {
      axios.get('/api/users/?groups__name=trip-user&page_size=0&is_active=True').then(r => {
        if (r.status === 200) {
          this.tripUsersList = r.data
        }
      })
    }
  }
}
</script>
