var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-spending-plan-reports'),"message":"Unauthorized to view Spending Reports"}},[(_vm.entry)?_c('div',[_c('UsdForcedAlert'),_c('url-breadcrumbs',{staticClass:"d-print-none",attrs:{"items":[
        ['Spending Plan List', { name: 'spending-plan-report-list' }],
        _vm.entry.url
      ]}}),_c('v-container',{attrs:{"fluid":""}},[_c('base-title',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" For Week Of "+_vm._s(_vm._f("formatDate")(_vm.entry.week_of))+" ")]},proxy:true}],null,false,1025574948)},[_vm._v(" Spending Report #"+_vm._s(_vm.entry.id)+" ")]),_c('small',[_vm._v(" Report Generated On "+_vm._s(_vm._f("formatDate")(_vm.entry.updated_at))+" "),_c('info-tooltip',[_vm._v(" Changes that occur after the generated date will not be reflected. ")])],1),_c('v-toolbar',{staticClass:"d-print-none",attrs:{"dense":"","flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.$store.getters.can('update-spending-plan-reports'),"small":"","text":""},on:{"click":_vm.sendEmails}},[_vm._v(" Send Emails ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.$store.getters.can('update-spending-plan-reports'),"small":"","primary":""},on:{"click":_vm.updateReport}},[_vm._v(" Update Report ")])],1),_vm._l((_vm.tables),function(table,user){return _c('v-row',{key:user},[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(table.partners.join(', '))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":table.rows,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.yard",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.yard)+" "),_c('br'),_c('small',[_c('router-link-id',{attrs:{"id":item.trip_id,"name":"trip"}},[_vm._v(" "+_vm._s(item.trip)+" ")])],1)]}},{key:"item.expected_pieces",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.expected_pieces))+" ")]}},{key:"item.expected_value",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyUSD")(item.expected_value))+" ")]}},{key:"item.is_cash",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-icon',{attrs:{"color":item.is_electronic ? 'primary' : 'white'}},[_vm._v(" mdi-bank-transfer ")]),_c('v-icon',{attrs:{"color":item.is_check ? 'primary' : 'white'}},[_vm._v(" mdi-checkbook ")]),_c('v-icon',{attrs:{"color":item.is_cash ? 'primary' : 'white'}},[_vm._v(" mdi-cash ")])],1)]}},{key:"item.cash_amount",fn:function(ref){
      var item = ref.item;
return [(item.cash_amount)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyUSD")(item.cash_amount))+" ")]):_c('span',[_vm._v(" No ")])]}},{key:"item.day_of_week",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.day_of_week || '--')+" ")]}},{key:"body.append",fn:function(ref){
      var header = ref.header;
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" Totals ")]),_c('td',{attrs:{"colspan":"4"}},_vm._l((_vm.cashBreakdown(table.rows)),function(info,i){return _c('div',{key:i},[_vm._v(" Cash Needed for "+_vm._s(info.day)+" "+_vm._s(_vm._f("currencyUSD")(info.amount))+" ")])}),0),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalColumn('expected_pieces', table.rows)))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currencyUSD")(_vm.totalColumn('cash_amount', table.rows)))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currencyUSD")(_vm.totalColumn('expected_value', table.rows)))+" ")])])]}}],null,true)})],1)],1)],1)],1)}),_vm._l((_vm.emptyTrips),function(trip,index){return _c('v-row',{key:index},[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(trip.trip_partners.join(', '))+" ")]),_c('v-card-text',[_c('router-link-id',{staticClass:"ml-2",attrs:{"id":trip.trip_id,"name":"trip"}},[_vm._v(" "+_vm._s(trip.trip)+" ")]),_vm._v(" - "+_vm._s(trip.trip_comments ? trip.trip_comments : 'No sales Listed')+" ")],1)],1)],1)],1)})],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }