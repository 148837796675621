<template>
  <unauthorized
    :authorized="$store.getters.can('view-trips')"
    message="Unauthorized to view Trips"
  >
    <div v-if="entry">
      <url-breadcrumbs
        :items="[
          ['Trip List', { name: 'trip-list' }],
          entry.url
        ]"
      />
      <v-container fluid>
        <base-title>
          Trip #{{ entry.id }}
          {{ entry.name || '--' }}
          <template v-slot:subtitle>
            {{ entry.date_start | formatDate }} to {{ entry.date_end | formatDate }}
          </template>
        </base-title>
        <h4>
          {{ stateName }}
        </h4>
        <p>
          {{ entry.comments }}
        </p>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card
              outlined
              class="mb-2"
            >
              <v-card-text>
                <span class="subtitle-2">
                  Partners
                </span>
                <partner-list-chips :partners="entry.users_details" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <expenses-card :trip="entry" />
          </v-col>
        </v-row>
      </v-container>
      <trip-option-dialog
        v-model="editingDialog"
        :trip="entry"
      />
    </div>
    <text-fab
      v-if="!(!['PE', 'AP'].includes(entry.status) || !this.$store.getters.isTripMember(this.entry) || !$store.getters.can('edit-trip'))"
      bottom
      right
      fixed
      icon-text="mdi-pencil-outline"
      color="primary"
      @click="openEditingDialog"
    >
      Edit / Options
    </text-fab>
  </unauthorized>
</template>

<script>
import ExpensesCard from '@/views/Trips/components/ExpensesCard'
import TripOptionDialog from '@/views/Trips/components/TripOptionDialog'
import PartnerListChips from '@/views/Trips/components/PartnerListChips'

export default {
  name: 'TripView',
  components: { PartnerListChips, TripOptionDialog, ExpensesCard },
  data: () => ({
    editingDialog: false,
    stateName: ''
  }),
  computed: {
    // Gets the lab entry from the store
    entry () {
      return this.$store.state['tripStore'].items[this.$route.params.id]
    }
  },
  methods: {
    openEditingDialog () {
      this.editingDialog = true
    },
    // Uses the state code from the entity to assign the state name
    setStateName () {
      const state = this.$store.getters['getStateList'].find(state => state.value === this.entry.blueprint_state)
      if (state) this.stateName = state.text
    }
  },
  mounted () {
    this.$store.dispatch('expenseStore/fetchExpenseGroup')
    this.$store.dispatch('tripStore/fetchTrip', this.$route.params.id).then(() => {
      this.setStateName()
    })
  }
}
</script>
