<template>
  <edit-dialog
    :value="value"
    headline-text="Edit Trip Sale"
    @input="$emit('input', $event)"
    @accept="updateTripSale"
    :inputs-valid="['PE', 'AP'].includes(trip.status)"
  >
    <h3>
      <router-link-id
        :id="sale.yard_details.id"
        name="yard"
      >
        {{ sale.yard_details.name.toUpperCase() }}
      </router-link-id>
      <info-tooltip>
        Yard information is pulled directly from the yard page.
      </info-tooltip>
    </h3>
    <v-list dense>
      <v-list-item
        v-if="sale.yard_details && sale.yard_details.contact"
      >
        <v-list-item-action>
          <v-icon>
            mdi-contacts-outline
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <a>
            {{ sale.yard_details.contact }}
          </a>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-btn
            small
            :href="`https://maps.google.com/?q=${getAddress(sale.yard_details)}`"
            target="_blank"
          >
            <v-icon>
              mdi-map-outline
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          {{ getAddress(sale.yard_details) }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="sale.yard_details && sale.yard_details.phone"
      >
        <v-list-item-action>
          <v-btn
            small
            :href="`tel:${sale.yard_details.phone}`"
            target="_blank"
          >
            <v-icon>
              mdi-phone-outline
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          {{ sale.yard_details.phone }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="sale.yard_details && sale.yard_details.email"
        :href="`mailto: ${sale.yard_details.email}`"
        target="_blank"
      >
        <v-list-item-action>
          <v-icon>
            mdi-email-outline
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          {{ sale.yard_details.email }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-form
      :disabled="!['PE', 'AP'].includes(trip.status) || !$store.getters.isTripMember(trip)"
    >
      <v-row>
        <v-col
          cols="6"
        >
          <v-select
            label="Day of Week"
            v-model="dayOfWeek"
            :items="$store.state['tripSaleStore'].weekDayChoices"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-select
            label="Sale Status"
            v-model="saleStatus"
            :items="$store.state['tripSaleStore'].saleStatusOptions"
          />
          <small
            v-if="saleStatus === 'PR'"
            class="red--text"
          >
            <strong>PROVISIONAL</strong> trip sales will not appear on the Spending Plan Report.
          </small>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="expectedPieces"
            label="Expected Pieces"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="expectedValue"
            label="Expected Value"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row v-if="expectedValue > 0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-checkbox
            label="Cash"
            v-model="isCash"
            hint="If selected please enter the cash amount"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-checkbox
            label="Check"
            v-model="isCheck"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-checkbox
            label="Electronic"
            v-model="isElectronic"
          />
        </v-col>
      </v-row>
      <v-row v-if="isCash">
        <v-col>
          <v-text-field
            type="number"
            label="Cash Amount"
            v-model="cashAmount"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="comments"
            label="Comments"
            rows="4"
          />
        </v-col>
      </v-row>
      <!--Could be much better by getting a curated list of possible check ins-->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-text>
              Check In:
              <router-link-id
                v-if="sale.check_in_id"
                name="check-in"
                :id="sale.check_in_id"
              >
                {{ sale.check_in_id }}
              </router-link-id>
              <span v-else>--</span>
            </v-card-text>
          </v-card>
          <v-row
            justify="center"
            align="center"
          >
            <v-col cols="6">
              <v-btn
                :disabled="!['PE', 'AP'].includes(trip.status) || !$store.getters.isTripMember(trip)"
                small
                @click="getAvailableCheckIns"
              >
                Get attachable check ins
              </v-btn>
            </v-col>
            <v-col
              cols="6"
            >
              <v-switch
                label="Limit to 60 days"
                v-model="limitMonths"
              />
            </v-col>
          </v-row>
          <v-select
            :disabled="availableCheckIns.length === 0"
            v-model="checkIn"
            :items="availableCheckIns"
            return-object
            item-text="id"
            label="Check In Select"
            hint="Only check ins that are not already attached and belong to the sale's yard."
          />
        </v-col>
      </v-row>
    </v-form>
  </edit-dialog>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'EditSale',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    sale: {
      type: Object,
      required: true
    },
    trip: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    limitMonths: true,
    availableCheckIns: [],
    checkIn: null,
    expectedPieces: 0,
    expectedValue: 0,
    comments: '',
    dayOfWeek: 'MO',
    isCash: false,
    cashAmount: 0,
    isCheck: false,
    isElectronic: false,
    saleStatus: null
  }),
  watch: {
    expectedPieces: function (val) {
      if (val === '') {
        this.expectedPieces = 0
      }
    },
    expectedValue: function (val) {
      if (val === '') {
        this.expectedValue = 0.00
      }
    },
    value: {
      immediate: true,
      handler (opened) {
        if (!opened) return
        this.availableCheckIns = []
        // this.checkIn = this.sale.checkin_id -- not tracked in this
        this.expectedPieces = this.sale.expected_pieces
        this.expectedValue = this.sale.expected_value
        this.comments = this.sale.comments
        this.dayOfWeek = this.sale.day_of_week
        this.isCash = this.sale.is_cash
        this.cashAmount = this.sale.cash_amount
        this.isCheck = this.sale.is_check
        this.isElectronic = this.sale.is_electronic
        this.saleStatus = this.sale.sale_status
      }
    }
  },
  methods: {
    async updateTripSale () {
      let payload = {
        expected_pieces: this.expectedPieces,
        expected_value: this.expectedValue,
        comments: this.comments,
        day_of_week: this.dayOfWeek,
        is_cash: this.isCash,
        cash_amount: this.cashAmount,
        is_check: this.isCheck,
        is_electronic: this.isElectronic,
        sale_status: this.saleStatus
      }
      if (this.checkIn && this.checkIn.url) payload.check_in = this.checkIn.url
      await this.$store.dispatch('tripSaleStore/patchTripSale', { id: this.sale.id, payload })
      this.$emit('updated')
      this.$emit('input', false)
    },
    getAddress (yard) {
      return `${yard.address} ${yard.city}, ${yard.state} ${yard.zip_code}`
    },
    getAvailableCheckIns () {
      if (!this.sale.yard_details) {
        this.$store.commit('setSnackbarError', 'No yard attached, unable to get check ins')
        return
      }
      const m = moment()
      m.subtract(3, 'months')
      let params = {
        'yard__id': this.sale.yard_details.id,
        'page_size': 0,
        'fields': 'url,id',
        'tripsale__isnull': true
      }
      if (this.limitMonths) params.created_at__gte = m.format('MM/DD/YYYY')
      axios.get('/api/check_ins/', { params })
        .then(r => {
          if (r.status !== 200) return
          if (r.data.length === 0) {
            this.$store.commit('setSnackbarInfo', 'No CheckIns Available')
          }
          this.availableCheckIns = r.data
        })
    }
  }
}
</script>
