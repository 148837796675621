<template>
  <div>
    <v-form
      v-if="$store.getters.isTripMember(trip)"
      @submit="addYard"
    >
      <v-card>
        <v-card-subtitle>
          Add a Yard
        </v-card-subtitle>
        <v-card-text>
          <search-autocomplete
            v-model="yardToAdd"
            :params="{ page_size: 0, fields: 'name,url,state', ordering: 'name', status: 'GS' }"
            label="Yard Search (Good Standing)"
            :api-path="apiPath"
            :format-entry="x => ({ friendlyName: `${x.name} (${x.state})`, name: x.name, url: x.url })"
          />
          <p
            class="warning--text"
            v-if="yardAlreadyIncluded"
          >
            This yard is already included.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            type="submit"
          >
            Add Yard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-card flat>
      <v-card-subtitle>
        Sales
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :dense="readonly"
          sort-by="yard_details.name"
          :headers="headers"
          :items="trip.tripsale_set_details"
          disable-pagination
          hide-default-footer
          @click:row="openEditDialog"
        >
          <template v-slot:item.yard_details.name="{ item }">
            <small>
              {{ item.yard_details.name }}
            </small>
          </template>
          <template v-slot:item.check_in_id="{ item }">
            <router-link-id
              v-if="item.check_in_id"
              :id="item.check_in_id"
              name="check-in"
            >
              {{ item.check_in_id }}
            </router-link-id>
          </template>
          <template v-slot:item.comments="{ item }">
            <info-tooltip v-if="item.comments">
              <template v-slot:wrap-me>
                <v-btn
                  icon
                >
                  <v-icon>
                    mdi-comment-text-outline
                  </v-icon>
                </v-btn>
              </template>
              {{ truncateComments(item.comments) }}
            </info-tooltip>
          </template>
          <template v-slot:item.expected_pieces="{ item }">
            {{ item.expected_pieces > 0 ? item.expected_pieces : '--' }}
          </template>
          <template v-slot:item.expected_value="{ item }">
            {{ item.expected_value > 0 ? currencyFmt(item.expected_value) : '--' }}
          </template>
          <template v-slot:item.check_in_total_counts="{ item }">
            {{ item.check_in_total_counts > 0 ? item.check_in_total_counts : '--' }}
          </template>
          <template v-slot:item.check_in_total_prices="{ item }">
            {{ item.check_in_total_prices > 0 ? currencyFmt(item.check_in_total_prices) : '--' }}
          </template>
          <template slot="body.append">
            <tr>
              <th colspan="2">
                Totals
              </th>
              <th>
                {{ sumField('expected_pieces') }}
              </th>
              <th>
                {{ currencyFmt(sumField('expected_value')) }}
              </th>
              <th>
                {{ sumField('check_in_total_counts') }}
              </th>
              <th>
                {{ currencyFmt(sumField('check_in_total_prices')) }}
              </th>
              <th />
            </tr>
          </template>
        </v-data-table>
        <div class="text-center">
          <small>
            Click on a row for editing/details.
          </small>
        </div>
      </v-card-text>
      <edit-sale
        v-if="selectedSale"
        v-model="editSaleDialog"
        :sale="selectedSale"
        :trip="trip"
        @updated="refreshTrip"
      />
    </v-card>
  </div>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import EditSale from '@/views/Trips/components/EditSale.vue'

export default {
  name: 'SalesCard',
  components: { EditSale, SearchAutocomplete },
  props: {
    trip: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currency: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }),
    yardToAdd: null,
    addYardDialog: false,
    editSaleDialog: false,
    selectedSale: null,
    headers: [
      { text: 'Yard', value: 'yard_details.name' },
      { text: 'CI', value: 'check_in_id' },
      { text: 'Exp. Pc', value: 'expected_pieces' },
      { text: 'Exp. $', value: 'expected_value' },
      { text: 'Actual Pc', value: 'check_in_total_counts' },
      { text: 'Actual $', value: 'check_in_total_prices' },
      { text: '', value: 'comments' }
    ]
  }),
  computed: {
    apiPath () {
      return '/api/yards/'
    },
    /**
     * Returns true if yard already included in trip
     * @return {*}
     */
    yardAlreadyIncluded () {
      if (!this.yardToAdd) return false
      return this.trip.tripsale_set_details.find(sale => sale.yard_details.url === this.yardToAdd.url)
    }
  },
  methods: {
    // reloads the current trip
    refreshTrip () {
      this.yardToAdd = null
      return this.$store.dispatch('tripStore/fetchTrip', this.$route.params.id)
    },
    openEditDialog (sale) {
      this.selectedSale = sale
      this.editSaleDialog = true
    },
    // adds a sale yard to the current trip
    addYard () {
      if (!this.yardToAdd) return this.$store.commit('setSnackbarWarning', 'Yard is required')
      if (!this.yardToAdd.url) return this.$store.commit('setSnackbarError', 'Unable to add yard')
      this.$store.dispatch('tripSaleStore/createTripSale', {
        trip: this.trip.url,
        yard: this.yardToAdd.url
      }).then(this.refreshTrip)
    },
    truncateComments (comment) {
      return (comment.length <= 100) ? comment : comment.substring(0, 95) + '...'
    },
    // Uses a simple currency format or '--'
    currencyFmt (value) {
      return value === 0 ? '--' : this.currency.format(value)
    },
    // Used in the footer to get a total
    sumField (key) {
      return this.trip.tripsale_set_details.reduce((carry, val) => {
        if (val[key]) carry += Number(val[key])
        return carry
      }, 0)
    }
  }
}
</script>
